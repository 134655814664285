import DhivyaS from "../../../../assets/images/staffPhotos/Dhivya_S.jpg";
import Suryakala from "../../../../assets/images/staffPhotos/PaulrajSuryakala.jpg";
import RagunathR from "../../../../assets/images/staffPhotos/RagunathR.jpg";
import Bhuvaneshwari from "../../../../assets/images/staffPhotos/BuvaneshwariR.jpg";
import Manickavalli from "../../../../assets/images/staffPhotos/ManickavalliS.jpg";
import Nandhini from "../../../../assets/images/staffPhotos/NandhiniA.jpg";
import Velprabakaran from "../../../../assets/images/staffPhotos/VelprabakaranS.jpg";
import Suganthi from "../../../../assets/images/staffPhotos/SuganthiA.jpg";
import  Sivachandran from "../../../../assets/images/staffPhotos/Sivachandran.JPG"
import Santhoshkumar from "../../../../assets/images/staffPhotos/Santhoshkumar.jpeg";
import Elamathiyan from "../../../../assets/images/staffPhotos/Elamathiyan.jpeg";

import Mohanapriya from "../../../../assets/images/staffPhotos/MohanaPriyaR.jpg";
import Brindhadevi from "../../../../assets/images/staffPhotos/BrindhaDeviS.jpg";
import  Jayaprakash from "../../../../assets/images/staffPhotos/JAYAPRAKASH.jpg";
import AnbarasiD from "../../../../assets/images/staffPhotos/AnbarasiD.jpg";

import Sundharavadivu from "../../../../assets/images/staffPhotos/SundharavadivuS.jpg";
import Naveen from "../../../../assets/images/staffPhotos/NaveenkumarM.jpg";
import Rajanikanth from "../../../../assets/images/staffPhotos/maxiii.jpg";
import Sathiya from "../../../../assets/images/staffPhotos/SathiyaR.jpg";
import Senthilmurugan from "../../../../assets/images/staffPhotos/SenthilMuruganS.jpg";
import JeevanathiyalR from "../../../../assets/images/staffPhotos/JeevanathiyalR.jpg";
import Praveena from "../../../../assets/images/staffPhotos/Praveena.jpeg";
import Sabarish from "../../../../assets/images/staffPhotos/Sabarish.jpg";
import Preethi from "../../../../assets/images/staffPhotos/PreethiS.jpg";
import SridharanS from "../../../../assets/images/staffPhotos/SridharanS.jpg";
import Sudha from "../../../../assets/images/staffPhotos/Sudha.jpg";
import VetrimaniV from "../../../../assets/images/staffPhotos/VetrimaniV.jpg";
import VanithaSriJ from "../../../../assets/images/staffPhotos/VanithaSriJ.JPG";
import Mayakrishnan from "../../../../assets/images/staffPhotos/Mayakrishnan.jpg";
import Munusamy from "../../../../assets/images/staffPhotos/Munusamy.JPG";
import MOHAMEDANSARIRAJA from "../../../../assets/images/staffPhotos/MohamadAnsari.JPG";
import THATCHINAMOORTHY from "../../../../assets/images/staffPhotos/Thatchinamurthy.JPG";
import Pirithiraj from "../../../../assets/images/staffPhotos/Dr. U. PIRITHIRAJ.jpg";
import Revathi from "../../../../assets/images/staffPhotos/Revathi.jpeg";
import  Kalaivani  from "../../../../assets/images/staffPhotos/Dr.kalaivani.jpg";
import Geetha from "../../../../assets/images/staffPhotos/T.Geetha.jpeg";

export const FacultyData = [
 
    {
    id: 112,
    image: Suryakala,
    fullName: "Dr. PAULRAJ SURYAKALA",
    position: "Assistant Professor",
    department: "(Agronomy)",
    eMail: "Suryapskagri16@gmail.com",
    mobile: "9655500170",
    deptType: "Crop Management",
    dateOfBirth: "10.07.1994",
    dateOfJoining: "02.05.2022",
    educationalQualification: "M.Sc. (Agronomy), Ph.D",
    specialisation: "Agronomy",
    // teachingExperience: "",
    researchExperience: "3",
    // googleScholar: "",
    seminarsAttended: { national: "3", interNational: "" },
    conferenceAttended: { national: "1", interNational: "2" },
    WorkshopsAttended: { national: "9", interNational: "" },
    trainingsAttended: { national: "4", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    researchPapersPresented: { national: "1", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "6", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    bookschaptersPublished: { national: "2", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },
 
  {
    id: 130,
    image:  Kalaivani,
    fullName: "Dr.B.Kalaivani",
    position: "Assistant Professor",
    department: "(Agronomy)",
    eMail: "dr.kalaiagro@gmail.com",
    mobile: " ",
    deptType: "Crop Management",
    // dateOfBirth: "10.07.1994",
    // dateOfJoining: "02.05.2022",
    educationalQualification: "M.Sc. (Agronomy), Ph.D",
    specialisation: "Nutrient management and weed management",
    teachingExperience: "3",
    researchExperience: "2",
    // googleScholar: "",
    seminarsAttended: { national: "5", interNational: "2" },
    // conferenceAttended: { national: "1", interNational: "2" },
    WorkshopsAttended: { national: "3", interNational: "" },
    trainingsAttended: { national: "1", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    researchPapersPresented: { national: "1", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "6", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    bookschaptersPublished: { national: "2", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    awards: "1",
    // memberships: {},
  },

   {
    id: 113,
    image: RagunathR,
    fullName: "Dr. R. RAGUNATH",
    position: "Assistant Professor",
    department: "(SS&AC)",
    eMail: "raghunathrenganathan1991@gmail.com",
    mobile: "9159149993",
    deptType: "Crop Management",
    dateOfBirth: "19.09.1991",
    dateOfJoining: "04.06.2021",
    educationalQualification: "M.Sc (Agri), Ph.D.,",
    specialisation: "Soil science",
    teachingExperience: "2 Years",
    researchExperience: "3 Years",
    // googleScholar: "",
    seminarsAttended: { national: "5", interNational: "3" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "4", interNational: "" },
    trainingsAttended: { national: "2", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "2", interNational: "" },
    populararticlesPublished: { national: "", interNational: "" },
    bookschaptersPublished: { national: "", interNational: "" },
    booksPublished: { national: "", interNational: "" },
    awards: { national: "4" },
    memberships: { national: "1" },
  },
  {
    id:114,
    image: MOHAMEDANSARIRAJA,
    fullName: "Dr.A. MOHAMED ANSARI RAJA",
    position: "Assistant Professor",
    department: "Department of Soil Science and Agricultural Chemistry",
    eMail: "ansariraja95@gmail.com",
    mobile: "",
    deptType: "Crop Management",
    // dateOfBirth: "01/06/1987",
    // dateOfJoining: "07/06/2021",
    educationalQualification: "Ph.D.,",
    specialisation: "Soil fertility, Soil survey, GIS",
    // teachingExperience: "18 months",
    researchExperience: "3 years",
    // googleScholar: "",
    seminarsAttended: { national: "4", interNational: "5" },
    conferenceAttended: { national: "4", interNational: "4" },
    WorkshopsAttended: { national: "6", interNational: "" },
    trainingsAttended: { national: "2", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    researchPapersPresented: { national: "3", interNational: "3" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "4", interNational: "1" },
    populararticlesPublished: { national: "2", interNational: "" },
    bookschaptersPublished: { national: "7", interNational: "" },
    // booksPublished: { national: "3", interNational: "" },
    awards: { national: "9" },
    // memberships: { national: "2" },
  },

  {
    id: 132,
    image: Geetha,
    fullName: "Dr. T. Geetha",
    position: "Assistant Professor",
    department: "(Agricultural Microbiology )",
    eMail: "geethamailsu@gmail.com",
    mobile: " ",
    deptType: "Crop Management",
    dateOfBirth: "",
    dateOfJoining: "",
    educationalQualification: "Ph.d",
    // specialisation: "",
    teachingExperience: "9 years",
    researchExperience: "4 years",
    // googleScholar: "",
    seminarsAttended: { national: "11", interNational: "5" },
    // conferenceAttended: { national: "1", interNational: "" },
    WorkshopsAttended: { national: "24", interNational: "" },
    // trainingsAttended: { national: "", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "1", interNational: "4" },
    populararticlesPublished: { national: "1", interNational: "" },
    bookschaptersPublished: { national: "1", interNational: "" },
    booksPublished: { national: "1", interNational: "" },
    awards: "4",
    // memberships: {},
  },


  //   {
  //   id: 115,
  //   image: Bhuvaneshwari,
  //   fullName: "Ms. R. BHUVANESHWARI",
  //   position: "Assistant Professor",
  //   department: "(Agricultural Microbiology )",
  //   eMail: "rajenbhuvi@gmail.com",
  //   mobile: "9585609476",
  //   deptType: "Crop Management",
  //   dateOfBirth: "29/10/1996",
  //   dateOfJoining: "22/08/2022",
  //   educationalQualification: "M.sc (Agricultural Microbiology)",
  //   // specialisation: "",
  //   // teachingExperience: "",
  //   // researchExperience: "",
  //   // googleScholar: "",
  //   // seminarsAttended: { national: "", interNational: "" },
  //   conferenceAttended: { national: "1", interNational: "" },
  //   WorkshopsAttended: { national: "1", interNational: "" },
  //   // trainingsAttended: { national: "", interNational: "" },
  //   // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
  //   // reviewPapersPresented: { national: "", interNational: "" },
  //   // researchPapersPresented: { national: "", interNational: "" },
  //   // reviewPapersPublished: { national: "", interNational: "" },
  //   // researchPapersPublished: { national: "", interNational: "" },
  //   // populararticlesPublished: { national: "", interNational: "" },
  //   // bookschaptersPublished: { national: "", interNational: "" },
  //   // booksPublished: { national: "", interNational: "" },
  //   // awards: {},
  //   // memberships: {},
  // },
  {
    id:135,
    image: Elamathiyan,
    fullName: "Mr.P.Elamathiyan",
    position: "Assistant Professor",
    department: "(Agronomy)",
    eMail: "p.ramesh.elamathi@gmail.com",
    mobile: "",
    deptType: "Crop Management",
    dateOfBirth: "",
    dateOfJoining: "",
    educationalQualification: "M.Sc. (Agronomy)",
    specialisation: "Agronomy",
    teachingExperience: "9 years",
    researchExperience: "2 Years",
    // googleScholar: "",
    seminarsAttended: { national: "3", interNational: "2" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "5", interNational: "" },
    trainingsAttended: { national: "3", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "3", interNational: "2" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    booksPublished: { national: "1", interNational: "" },
    awards: 3,
    // memberships: {},
  },

  {
    id:111,
    image: DhivyaS,
    fullName: "Ms. S. DHIVYA",
    position: "Assistant Professor",
    department: "(Agronomy)",
    eMail: "dhivyasubbu98@gmail.com",
    mobile: "9150240101",
    deptType: "Crop Management",
    dateOfBirth: "12.07.1998",
    dateOfJoining: "25.02.2022",
    educationalQualification: "M.Sc. (Agronomy)",
    specialisation: "Agronomy",
    // teachingExperience: "",
    researchExperience: "2 Years",
    // googleScholar: "",
    // seminarsAttended: { national: "", interNational: "" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "5", interNational: "" },
    trainingsAttended: { national: "1", interNational: "" },
    facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "1", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },
 
  {
    id: 116,
    image: Manickavalli,
    fullName: "Ms. S. MANICKAVALLI",
    position: "Assistant Professor",
    department: "(Biochemistry)",
    eMail: "smanickavalli18@gmail.com",
    mobile: "9176585499",
    deptType: "Crop Management",
    dateOfBirth: "18.09.1998",
    dateOfJoining: "15.07.2022",
    educationalQualification: "M.sc Biochemistry",
    // specialisation: "",
    teachingExperience: "1 Year",
    researchExperience: "1 Month",
    // googleScholar: "",
    seminarsAttended: { national: "1", interNational: "" },
    conferenceAttended: { national: "5", interNational: "" },
    // WorkshopsAttended: { national: "", interNational: "" },
    trainingsAttended: { national: "1", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    researchPapersPresented: { national: "1", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },
  //  {
  //   id: 117,
  //   image: Nandhini,
  //   fullName: "Ms. A. NANDHINI",
  //   position: "Assistant Professor",
  //   department: "(Agricultural Engineering)",
  //   eMail: "nanthinia1999@gmail.com",
  //   mobile: "8344615576",
  //   deptType: "Crop Management",
  //   dateOfBirth: "20-06-1999",
  //   dateOfJoining: "21-09-2022",
  //   educationalQualification: "M.E  (Energy Engineering)",
  //   // specialisation: "",
  //   // teachingExperience: "",
  //   // researchExperience: "",
  //   // googleScholar: "",
  //   // seminarsAttended: { national: "", interNational: "" },
  //   conferenceAttended: { national: "1", interNational: "" },
  //   WorkshopsAttended: { national: "1", interNational: "" },
  //   trainingsAttended: { national: "1", interNational: "" },
  //   // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
  //   // reviewPapersPresented: { national: "", interNational: "" },
  //   // researchPapersPresented: { national: "", interNational: "" },
  //   // reviewPapersPublished: { national: "", interNational: "" },
  //   // researchPapersPublished: { national: "", interNational: "" },
  //   // populararticlesPublished: { national: "", interNational: "" },
  //   // bookschaptersPublished: { national: "", interNational: "" },
  //   // booksPublished: { national: "", interNational: "" },
  //   // awards: {},
  //   // memberships: {},
  // },

  {
    id: 131,
    image: Revathi,
    fullName: "A.R.Revathi",
    position: "Assistant Professor",
    department: "(Agricultural Engineering)",
    eMail: "arrevathibtechagri@gmail.com",
    // mobile: "8344615576",
    deptType: "Crop Management",
    // dateOfBirth: "20-06-1999",
    // dateOfJoining: "21-09-2022",
    // educationalQualification: "M.E  (Energy Engineering)",
    specialisation: "Hydrological modelling ",
    teachingExperience: "4 years",
    researchExperience: "6 Month",
    // googleScholar: "",
    seminarsAttended: { national: "1", interNational: "1" },
    // conferenceAttended: { national: "1", interNational: "" },
    WorkshopsAttended: { national: "2", interNational: "" },
    // trainingsAttended: { national: "1", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },

    {
    id: 118,
    image: Velprabakaran,
    fullName: "Dr. S. VELPRABAKARAN",
    position: "Assistant Professor",
    department: "(Plant Breeding and Genetics)",
    eMail: "anbudanvel@gmail.com",
    mobile: "9659970832",
    deptType: "Crop Improvement",
    dateOfBirth: "'26.08.1992",
    dateOfJoining: "16/04/2022",
    educationalQualification: "Ph.D.,",
    // specialisation: "",
    teachingExperience: "2 Years",
    researchExperience: "5 Years",
    googleScholar:
      "https://scholar.google.com/citations?user=QWIwcyMAAAAJ&hl=en",
    seminarsAttended: { national: "10", interNational: "5" },
    conferenceAttended: { national: "9", interNational: "1" },
    WorkshopsAttended: { national: "5", interNational: "" },
    trainingsAttended: { national: "2", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    reviewPapersPresented: { national: "2", interNational: "" },
    researchPapersPresented: { national: "2", interNational: "1" },
    reviewPapersPublished: { national: "11", interNational: "2" },
    // researchPapersPublished: { national: "", interNational: "" },
    populararticlesPublished: { national: "5", interNational: "" },
    bookschaptersPublished: { national: "2", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    memberships: { national: "2" },
  },
    {
    id: 119,
    image: Suganthi,
    fullName: "Dr. A. SUGANTHI",
    position: "Assistant Professor",
    department: "(Seed Science and Technology)",
    eMail: "suganthiathi09@gmail.com",
    mobile: "9789228396",
    deptType: "Crop Improvement",
    dateOfBirth: "09.06.1991",
    dateOfJoining: "11.04.2022",
    educationalQualification: "Ph.D.,",
    // specialisation: "",
    teachingExperience: "3 years",
    // researchExperience: "",
    // googleScholar: "",
    seminarsAttended: { national: "10", interNational: "" },
    conferenceAttended: { national: "1", interNational: "" },
    WorkshopsAttended: { national: "2", interNational: "" },
    // trainingsAttended: { national: "", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "", interNational: "" },
    populararticlesPublished: { national: "1", interNational: "" },
    bookschaptersPublished: { national: "1", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    memberships: { national: "2" },
  },
  {
    id: 110,
    image:Sivachandran ,
    fullName: "Dr. R . SIVACHANDRAN",
    position: "Assistant Professor",
    department: "(Plant Biotechnology)",
    eMail: "Siva.biotek@gmail.com ",
    mobile: " ",
    deptType: "Crop Improvement",
    dateOfBirth: " ",
    dateOfJoining: " ",
    educationalQualification: "Ph.D., ",
    specialisation: "Plant Tissue culture, Genetic transformation, Back-cross breeding and Molecular analysis",
    teachingExperience: "3 Years",
    researchExperience: "8 Years",
    // googleScholar: "",
    seminarsAttended: { national: "4", interNational: "3" },
    conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "4", interNational: "" },
    trainingsAttended: { national: "1", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "1", interNational: "" },
    // researchPapersPresented: { national: "5", interNational: "3" },
    // // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "", interNational: "" },
    populararticlesPublished: { national: "1", interNational: "" },
    bookschaptersPublished: { national: "3", interNational: "" },
    booksPublished: { national: "2", interNational: "" },
    awards: { national: "2" },
    // memberships: { International: "ISCA" },
  },


    {
    id: 111,
    image: Santhoshkumar,
    fullName: "Mr.C.Santhoshkumar",
    position: "Assistant Professor ",
    department: "Plant Breeding and Genetics ",
    eMail: "csanthoshkumar2020@gmail.com",
    // mobile: "",
    deptType: "Crop Improvement",
    dateOfBirth: "",
    dateOfJoining: "",
    educationalQualification: " ",
    specialisation: " ",
    teachingExperience: "",
    researchExperience: " 1",
    // googleScholar: "",
    seminarsAttended: { national: "24", interNational: "12" },
    conferenceAttended: { national: "15", interNational: "7" },
    WorkshopsAttended: { national: "20", interNational: "11" },
    trainingsAttended: { national: "8", interNational: "3" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "1", interNational: "" },
    // researchPapersPublished: { national: "61", interNational: "11" },
    // populararticlesPublished: { national: "54", interNational: "" },
    // bookschaptersPublished: { national: "14", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },
 
   {
    id: 134,
    image: Preethi,
    fullName: "Dr. S. PREETHI",
    position: "Assistant Professor",
    department: "Agricultural Entomology",
    eMail: "preethis0004@gmail.com",
    // mobile: "9150240101",
    deptType: "Crop Protection",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    educationalQualification: "Ph.D.,",
    specialisation: "Toxicology, Integrated Pest Management",
    // teachingExperience: "5 years and 10 month",
    researchExperience: "4 Years",
    // googleScholar: "",
    seminarsAttended: { national: "2", interNational: "1" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "2", interNational: "" },
    // trainingsAttended: { national: "5", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "3", interNational: "3" },
    populararticlesPublished: { national: "1", interNational: "" },
    bookschaptersPublished: { national: "1", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    awards: { national: "1" },
    // memberships: { national: "4" },
  },
  {
    id: 113,
    image: Pirithiraj,
    fullName: "Dr.U.Pirithiraj",
    position: "Assistant Professor",
    department: "Agricultural Entomology",
    eMail: "u.pirithiraj@gmail.com",
    // mobile: "9150240101",
    deptType: "Crop Protection",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    educationalQualification: "Ph.D.,",
    specialisation: "Insect Plant Interactions and Host Plant Resistance",
    // teachingExperience: "5 years and 10 month",
    researchExperience: "3 Years",
    // googleScholar: "",
    seminarsAttended: { national: "6", interNational: "4" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "1", interNational: "" },
    trainingsAttended: { national: "8", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "3", interNational: "3" },
    populararticlesPublished: { national: "1", interNational: "" },
    bookschaptersPublished: { national: "1", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    awards: { national: "3" },
    // memberships: { national: "4" },
  },

  // {
  //   id: 114,
  //   image: Mohanapriya,
  //   fullName: "Ms. R. MOHANAPRIYA",
  //   position: "Assistant Professor",
  //   department: "(Plant Pathology)",
  //   eMail: "ra.mohanapriya512@gmail.com",
  //   mobile: "9003460633",
  //   deptType: "Crop Protection",
  //   dateOfBirth: "05.06.1992",
  //   dateOfJoining: "18.01.2019",
  //   educationalQualification: "M.Sc.Agri",
  //   specialisation:
  //     "Commercial Mushroom Production & Bio control agents in disease management",
  //   teachingExperience: "3.5 Years",
  //   // researchExperience: "",
  //   // googleScholar: "",
  //   seminarsAttended: { national: "3", interNational: "1" },
  //   // conferenceAttended: { national: "", interNational: "" },
  //   WorkshopsAttended: { national: "1", interNational: "" },
  //   // trainingsAttended: { national: "2", interNational: "" },
  //   // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
  //   // reviewPapersPresented: { national: "", interNational: "" },
  //   // researchPapersPresented: { national: "", interNational: "" },
  //   // reviewPapersPublished: { national: "", interNational: "" },
  //   researchPapersPublished: { national: "5", interNational: "" },
  //   populararticlesPublished: { national: "4", interNational: "" },
  //   bookschaptersPublished: { national: "2", interNational: "" },
  //   // booksPublished: { national: "", interNational: "" },
  //   // awards: {},
  //   memberships: { national: "1" },
  // },
  {
    id: 115,
    image: Brindhadevi,
    fullName: "Dr. S. BRINDHADEVI",
    position: "Assistant Professor",
    department: "(Plant Pathology)",
    eMail: "brindadevi555@gmail.com",
    mobile: "7373462110",
    deptType: "Crop Protection",
    dateOfBirth: "10-08-1994",
    dateOfJoining: "28-02-2022",
    educationalQualification: "Ph.d,. ",
    // specialisation: "",
    // teachingExperience: "",
    researchExperience: "5 years",
    // googleScholar: "",
    seminarsAttended: { national: "4", interNational: "" },
    conferenceAttended: { national: "5", interNational: "5" },
    WorkshopsAttended: { national: "3", interNational: "" },
    trainingsAttended: { national: "2", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    researchPapersPresented: { national: "2", interNational: "1" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },

  {
    id: 135,
    image: Jayaprakash,
    fullName: "Dr. k. JAYAPRAKASH",
    position: "Assistant Professor",
    department: "(Plant Pathology)",
    eMail: "jaypathology5@gmail.com",
    // mobile: "7373462110",
    deptType: "Crop Protection",
    // dateOfBirth: "10-08-1994",
    // dateOfJoining: "28-02-2022",
    educationalQualification: "Ph.d,. ",
    specialisation: "Bio control",
    teachingExperience: "1 year and 11 Month",
    researchExperience: "5 years",
    // googleScholar: "",
    // seminarsAttended: { national: "4", interNational: "" },
    // conferenceAttended: { national: "5", interNational: "5" },
    // WorkshopsAttended: { national: "3", interNational: "" },
    // trainingsAttended: { national: "2", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "2", interNational: "1" },
    reviewPapersPublished: { national: "", interNational: "2" },
    // researchPapersPublished: { national: "", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },
   {
    id: 116,
    image: AnbarasiD,
    fullName: "Dr. D. ANBARASI",
    position: "Assistant Professor",
    department: "(Department of Horticulture)",
    eMail: "anbarasidevar@gmail.com",
    mobile: "9787579474",
    deptType: "Horticulture",
    dateOfBirth: "17-07-1995",
    dateOfJoining: "04.03.2022",
    educationalQualification: "Ph.D., ",
    specialisation: "Department of Horticulture",
    // teachingExperience: "",
    researchExperience: "5 Years",
    // googleScholar: "",
    seminarsAttended: { national: "1", interNational: "" },
    conferenceAttended: { national: "3", interNational: "2" },
    WorkshopsAttended: { national: "5", interNational: "" },
    trainingsAttended: { national: "1", interNational: "" },
    facultyDevelopmentProgrammeAttended: { national: "2", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    researchPapersPresented: { national: "2", interNational: "1" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "1", interNational: "5" },
    // populararticlesPublished: { national: "", interNational: "" },
    bookschaptersPublished: { national: "2", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },
  {
    id: 117,
    image: SridharanS,
    fullName: "Dr. S. SRIDHARAN",
    position: "Assistant Professor",
    department: "Horticulture",
    eMail: "Sridharanvdm@gmail.com",
    // mobile: "9150240101",
    deptType: "Horticulture",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    educationalQualification: "Ph.D.,",
    specialisation: "Vegetable Science",
    teachingExperience: "5 years and 3 month",
    researchExperience: "3 years and 7 months",
    // googleScholar: "",
    seminarsAttended: { national: "20", interNational: "5" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "7", interNational: "" },
    trainingsAttended: { national: "3", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "8", interNational: "2" },
    populararticlesPublished: { national: "13", interNational: "" },
    bookschaptersPublished: { national: "1", interNational: "" },
    booksPublished: { national: "3", interNational: "" },
    awards: { national: "10" },
    memberships: { national: "5" },
  },
  {
    id: 136,
    image: Praveena,
    fullName: "Mrs. P. Praveena",
    position: "Assistant Professor",
    department: "Horticulture",
    eMail: "ragavpraveena@gmail.com",
    // mobile: "9150240101",
    deptType: "Horticulture",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    educationalQualification: "",
    specialisation: "Fruit Science",
    // teachingExperience: "",
    researchExperience: "1 years ",
    // googleScholar: "",
    seminarsAttended: { national: "2", interNational: "" },
    conferenceAttended: { national: "1", interNational: "" },
    WorkshopsAttended: { national: "1", interNational: "" },
    trainingsAttended: { national: "4", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "8", interNational: "2" },
    populararticlesPublished: { national: "1", interNational: "" },
    // bookschaptersPublished: { national: "1", interNational: "" },
    // booksPublished: { national: "3", interNational: "" },
    // awards: { national: "10" },
    // memberships: { national: "5" },
  },

  {
    id: 118,
    image: VanithaSriJ,
    fullName: "Dr. J.VANITHASRI",
    position: "Assistant Professor",
    department: "Food Science and Nutrition  ",
    eMail: "vanitsri@gmail.com",
    // mobile: "9150240101",
    deptType: "Horticulture",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    educationalQualification: "Ph.D.,",
    specialisation: "Value addition ",
    teachingExperience: "4 years  ",
    researchExperience: "5 years",
    // googleScholar: "",
    seminarsAttended: { national: "1", interNational: "4" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "5", interNational: "" },
    trainingsAttended: { national: "8", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "4", interNational: "3" },
    // populararticlesPublished: { national: "10", interNational: "" },
    bookschaptersPublished: { national: "6", interNational: "" },
    booksPublished: { national: "4", interNational: "2" },
    awards: { national: "2" },
    memberships: { national: "4" },
  },
  
   {
    id: 119,
    image: Sundharavadivu,
    fullName: "Dr. S. SUNDHARAVADIVU",
    position: "Assistant Professor",
    department: "(Agrl. Extension)",
    eMail: "sundhariasbhu@gmail.com",
    mobile: "7397611927",
    deptType: "Social Sciences",
    dateOfBirth: "01/06/1987",
    dateOfJoining: "07/06/2021",
    educationalQualification: "Ph.D.,",
    // specialisation: "",
    teachingExperience: "18 months",
    researchExperience: "5 years",
    // googleScholar: "",
    seminarsAttended: { national: "4", interNational: "2" },
    conferenceAttended: { national: "4", interNational: "4" },
    WorkshopsAttended: { national: "6", interNational: "" },
    trainingsAttended: { national: "2", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "4", interNational: "1" },
    // populararticlesPublished: { national: "", interNational: "" },
    bookschaptersPublished: { national: "2", interNational: "" },
    booksPublished: { national: "3", interNational: "" },
    // awards: {},
    memberships: { national: "2" },
  },
  {
    id: 120,
    image: THATCHINAMOORTHY,
    fullName: "Dr.C.THATCHINAMOORTHY ",
    position: "Assistant Professor",
    department: "(Agrl. Extension)",
    eMail: "tmthatchupeaceful@gmail.com",
    // mobile: "7397611927",
    deptType: "Social Sciences",
    // dateOfBirth: "01/06/1987",
    // dateOfJoining: "07/06/2021",
    educationalQualification: "Ph.D.,",
    // specialisation: "",
    teachingExperience: "2 years",
    researchExperience: "7 years",
    // googleScholar: "",
    seminarsAttended: { national: "53", interNational: "2" },
    // conferenceAttended: { national: "4", interNational: "4" },
    WorkshopsAttended: { national: "8", interNational: "" },
    trainingsAttended: { national: "19", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "9", interNational: "14" },
    populararticlesPublished: { national: "1", interNational: "" },
    bookschaptersPublished: { national: "11", interNational: "" },
    booksPublished: { national: "6", interNational: "" },
    awards: { national: "7" },
    // memberships: { national: "2" },
  },

  {
    id: 121,
    image: Naveen,
    fullName: "Dr. M. NAVEENKUMAR",
    position: "Assistant Professor",
    department: "(Agricultural economics)",
    eMail: "naveen13091991@gmail.com",
    mobile: "9952662234",
    deptType: "Social Sciences",
    dateOfBirth: "13-03-1991",
    dateOfJoining: "02-03-2022",
    educationalQualification: "M.Sc. (Agricultural economics)",
    specialisation: "Agricultural economics",
    // teachingExperience: "",
    researchExperience: "5 Years",
    // googleScholar: "",
    seminarsAttended: { national: "4", interNational: "" },
    conferenceAttended: { national: "3", interNational: "3" },
    WorkshopsAttended: { national: "5", interNational: "" },
    trainingsAttended: { national: "2", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    researchPapersPresented: { national: "1", interNational: "2" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "1", interNational: "1" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },
 
  {
    id: 133,
    image: Rajanikanth,
    fullName: "Dr.G.R. Rajanikanth,",
    position: "Professor & UPSC coordinator",
    department: "(Agricultural Economics)",
    eMail: "rrajanikanth2005@yahoo.com",
    mobile: "",
    deptType: "Social Sciences",
    dateOfBirth: "06/11/1979",
    dateOfJoining: "02/09/2024",
    educationalQualification: "M.Sc(Agri).,Ph.D",
    specialisation: "Agricultural economics",
    teachingExperience: "12 Years",
    researchExperience: "5 Years",
    // googleScholar: "",
    seminarsAttended: { national: "1", interNational: "2" },
    conferenceAttended: { national: "3", interNational: "3" },
    WorkshopsAttended: { national: "5", interNational: "" },
    trainingsAttended: { national: "2", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    researchPapersPresented: { national: "1", interNational: "2" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "1", interNational: "2" },
    populararticlesPublished: { national: "2", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {UPSC coordinator},
  },


  // {
  //   id: 122,
  //   image: Sathiya,
  //   fullName: "Dr. R. SATHIYA",
  //   position: "Assistant Professor",
  //   department: "(Agricultural Economics)",
  //   eMail: "rameshsathiya24@gmail.com",
  //   mobile: "9715267752",
  //   deptType: "Social Sciences",
  //   dateOfBirth: "23-05-1991",
  //   dateOfJoining: "08-09-2022",
  //   educationalQualification: "Ph.D.,",
  //   specialisation: "Statistics",
  //   teachingExperience: "3 Years",
  //   researchExperience: "7 years",
  //   // googleScholar: "",
  //   seminarsAttended: { national: "5", interNational: "2" },
  //   conferenceAttended: { national: "6", interNational: "3" },
  //   WorkshopsAttended: { national: "12", interNational: "" },
  //   trainingsAttended: { national: "6", interNational: "" },
  //   // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
  //   // reviewPapersPresented: { national: "", interNational: "" },
  //   researchPapersPresented: { national: "11", interNational: "5" },
  //   // reviewPapersPublished: { national: "", interNational: "" },
  //   researchPapersPublished: { national: "7", interNational: "5" },
  //   populararticlesPublished: { national: "2", interNational: "" },
  //   // bookschaptersPublished: { national: "", interNational: "" },
  //   // booksPublished: { national: "", interNational: "" },
  //   // awards: {},
  //   memberships: { national: "2" },
  // },
  {
    id: 123,
    image: Senthilmurugan,
    fullName: "Mr. S. SENTHIL MURUGAN",
    position: "Assistant Professor",
    department: "(COMPUTER SCIENCE)",
    eMail: "cssenthil452@gmail.com",
    mobile: "9751850010",
    deptType: "Social Sciences",
    dateOfBirth: "14.01.1984",
    dateOfJoining: "28.10.2021",
    educationalQualification: "MCA.,M.Ed.,M.PHIL.,",
    // specialisation: "",
    teachingExperience: "10 years",
    // researchExperience: "",
    // googleScholar: "",
    seminarsAttended: { national: "2", interNational: "1" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "1", interNational: "" },
    // trainingsAttended: { national: "", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },
  
  {
    id: 124,
    image: JeevanathiyalR,
    fullName: "Mrs. R. JEEVANATHIYAL",
    position: "Assistant Professor",
    department: "(Mathematics)",
    eMail: "jeevanathiyalravi@gmail.com",
    mobile: "7397118530",
    deptType: "Social Sciences",
    dateOfBirth: "06.05.1991",
    dateOfJoining: "15.07.2019",
    educationalQualification: "M.Sc.,M.Ed , M.Phil (Mathematics)",
    specialisation: "Teaching in Statistics",
    teachingExperience: "'7 years",
    // researchExperience: "",
    // googleScholar: "",
    seminarsAttended: { national: "1", interNational: "" },
    conferenceAttended: { national: "1", interNational: "" },
    // WorkshopsAttended: { national: "", interNational: "" },
    // trainingsAttended: { national: "", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "", interNational: "" },
    // populararticlesPublished: { national: "", interNational: "" },
    // bookschaptersPublished: { national: "", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: {},
    // memberships: {},
  },

  {
    id: 125,
    image: Sabarish,
    fullName: 'K.SABARISH ',
    position: "Assistant Professor",
    department: "Environmental Science",
    eMail: "sabarishanburks@gmail.com",
    // mobile: "9150240101",
    deptType: "Crop Management",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    educationalQualification: "M.Sc., (Ag)",
    // specialisation: "Phytoremediation",
    // teachingExperience: "5 years and 10 month",
    // researchExperience: "5 Years",
    // googleScholar: "",
    seminarsAttended: { national: "", interNational: "1" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "6", interNational: "" },
    // trainingsAttended: { national: "5", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "1", interNational: "" },
    // populararticlesPublished: { national: "1", interNational: "" },
    // bookschaptersPublished: { national: "1", interNational: "" },
    // booksPublished: { national: "", interNational: "" },
    // awards: { national: "1" },
    // memberships: { national: "4" },
  },
  {
    id: 126,
    image: Sudha,
    fullName: "Dr. K. SUDHA",
    position: "Assistant Professor",
    department: "Department of Tamil",
    eMail: "Ksudha7282@gmail.com",
    // mobile: "9150240101",
    deptType: "Social Sciences",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    educationalQualification: "Ph.D.,",
    specialisation: "Tamil Literature",
    teachingExperience: "1 years",
    researchExperience: "6 years",
    // googleScholar: "",
    seminarsAttended: { national: "2", interNational: "4" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "2", interNational: "" },
    // trainingsAttended: { national: "3", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "4", interNational: "2" },
    // populararticlesPublished: { national: "13", interNational: "" },
    // bookschaptersPublished: { national: "1", interNational: "" },
    // booksPublished: { national: "3", interNational: "" },
    // awards: { national: "10" },
    memberships: { national: "5" },
  },
  {
    id: 127,
    image: VetrimaniV,
    fullName: "Dr. V. VETRIMANI",
    position: "Assistant Professor",
    department: "English ",
    eMail: "vetrivengat@gmail.com",
    // mobile: "9150240101",
    deptType: "Social Sciences",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    educationalQualification: "Ph.D.,",
    specialisation: "American Literature ",
    teachingExperience: "9 years 06 months ",
    researchExperience: "2 years",
    // googleScholar: "",
    seminarsAttended: { national: "1", interNational: "1" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "2", interNational: "" },
    // trainingsAttended: { national: "3", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    researchPapersPublished: { national: "2", interNational: "2" },
    // populararticlesPublished: { national: "13", interNational: "" },
    // bookschaptersPublished: { national: "1", interNational: "" },
    // booksPublished: { national: "3", interNational: "" },
    // awards: { national: "10" },
    // memberships: { national: "5" },
  },
  {
    id: 128,
    image: Mayakrishnan,
    fullName: "Mr . MAYAKRISHNAN . G",
    position: "Librarian",
    department: "Library ",
    eMail: "maya.pbt@gmail.com",
    // mobile: "9150240101",
    deptType: "Social Sciences",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    // educationalQualification: "Ph.D.,",
    specialisation:
      " Library Information science and Plant Tissue Culture, Plant physiology",
    teachingExperience: "7 years ",
    // researchExperience: "2 years",
    // googleScholar: "",
    // seminarsAttended: { national: "1", interNational: "1" },
    // conferenceAttended: { national: "", interNational: "" },
    WorkshopsAttended: { national: "3", interNational: "" },
    // trainingsAttended: { national: "3", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "2", interNational: "2" },
    // populararticlesPublished: { national: "13", interNational: "" },
    // bookschaptersPublished: { national: "1", interNational: "" },
    booksPublished: { national: "1", interNational: "" },
    // awards: { national: "10" },
    // memberships: { national: "5" },
  },
  {
    id: 129,
    image: Munusamy,
    fullName: "Mr. Munusamy",
    position: "Physical Director",
    department: "PED ",
    // eMail: "maya.pbt@gmail.com",
    // mobile: "9150240101",
    deptType: "Social Sciences",
    // dateOfBirth: "12.07.1998",
    // dateOfJoining: "25.02.2022",
    // educationalQualification: "Ph.D.,",
    // specialisation:
    // " Library Information science and Plant Tissue Culture, Plant physiology",
    // teachingExperience: "7 years ",
    // researchExperience: "2 years",
    // googleScholar: "",
    // seminarsAttended: { national: "1", interNational: "1" },
    // conferenceAttended: { national: "", interNational: "" },
    // WorkshopsAttended: { national: "3", interNational: "" },
    // trainingsAttended: { national: "3", interNational: "" },
    // facultyDevelopmentProgrammeAttended: { national: "", interNational: "" },
    // reviewPapersPresented: { national: "", interNational: "" },
    // researchPapersPresented: { national: "", interNational: "" },
    // reviewPapersPublished: { national: "", interNational: "" },
    // researchPapersPublished: { national: "2", interNational: "2" },
    // populararticlesPublished: { national: "13", interNational: "" },
    // bookschaptersPublished: { national: "1", interNational: "" },
    // booksPublished: { national: "1", interNational: "" },
    // awards: { national: "10" },
    // memberships: { national: "5" },
  },

 
];
